#opinions-section{
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    background: var(--dark);
    color: var(--primary-light-gray);
    overflow-x: hidden;

    .container{
        width: 100%;
        max-width: var(--margined-max-width);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        div.heading{
            max-width: 700px;
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;

            h1{
                font-size: 3rem;
            }

            p{
                font-size: 1rem;
                text-indent: 30px;
                margin-bottom: 1rem;
            }
        }

        div.carousel{
            width: 100%;
            display: grid;
            grid-template-columns: 9fr 11fr 9fr;
            gap: 1rem;
            align-items: center;

            .opinion{
                width: 100%;
                display: flex;
                max-height: 20rem;
                flex-direction: column;
                padding: 1.25rem;
                background: var(--white);
                border-radius: .25rem;
                color: var(--primary-gray);

                &.main{
                    height: 115%;
                    padding: 1.5rem;
                }

                .heading{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 1rem;

                    img,
                    .fake-avatar{
                        width: 2.5rem;
                        border-radius: 1.25rem;
                        margin-right: 1rem;
                        border: 2px solid var(--primary-blue);
                    }

                    .fake-avatar{
                        height: 2.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.5rem;
    
                    }

                    .introduction{
                        padding-bottom: 0.25rem;
                        border-bottom: 1px solid var(--primary-light-gray);
                        flex-grow: 1;

                        h2{
                            font-size: 1rem;
                        }

                        h4{
                            font-size: .75rem;
                            font-weight: 200;
                        }
                    }
                }

                .content{
                    margin-bottom: 1rem;
                    flex-grow: 3;
                    font-size: .9rem;
                }

                .rating{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;

                    img {
                        width: 1.5rem;
                        margin-left: .25rem;
                    }
                }
            }
        }

        div.show-more{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 2rem;
            margin-bottom: 7rem;
        }
    }

    span.graphic-curve{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8rem 0 0 100vw;
        border-color: transparent transparent transparent var(--white);
        z-index: -1;

        &.shadow{
            border-width: 10rem 0 0 100vw;
            border-color: transparent transparent transparent var(--primary-light);
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px){
    #opinions-section{
    
        .container{
    
            
        }
    }
}

@media screen and (max-width: 767px) {
    #opinions-section{
        height: initial;
    
        .container{
            flex-direction: column;
            margin: 5rem 0;
    
            div.carousel{
                grid-template-columns: 1fr;
                gap: 1rem;
                align-items: center;

                .opinion{
                    width: 100%;
                    margin-bottom: 1rem;

                    &.main{
                        width: 100%;
                        height: initial;
                    }
                }
            }

            div.show-more{
                padding-top: 2rem;
                margin-bottom: 3rem;
            }
        }
    }
}