#contact-section{
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .container{
        width: 100%;
        max-width: var(--margined-max-width);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .text-container{
            max-width: 300px;
            min-width: 300px;
            margin-right: 5rem;

            h1{
                font-size: 3rem;
                color: var(--black);
            }

            .properties{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 1.5rem;
                font-size: .9rem;

                .property-container{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 1rem;

                    &:nth-of-type(4){
                        margin-bottom: 0;
                    }

                    img{
                        height: 1rem;
                        padding-right: 1rem;
                    }
                }
            }

            .information{
                font-size: .9rem;
                color: var(--primary-gray);
                text-indent: 30px;
                margin-bottom: 1rem;
                line-height: 1.25rem;
                margin-bottom: 1.5rem;
                border-top: 1px solid var(--dark);
                border-bottom: 1px solid var(--dark);
                padding: .5rem 0 .75rem 0;
            }

            .hours{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-size: .9rem;

                .hours_header{
                    display: flex;
                    flex-direction: row;
                    margin-bottom: .75rem;

                    img{
                        height: 1.25rem;
                        padding-right: 1rem;
                    }
                    h3{
                        font-weight: 100;
                        text-align: left;
                    }
                }

                .hour-container{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: .6rem;
                    margin-left: 3rem;

                    h4{
                        width: 50%;
                        font-weight: 100;
                        text-align: left;
                    }

                    h4.today{
                        font-weight: 500;
                        color: var(--primary);
                    }
                }
            }

        }

        iframe{
            margin-top: 1.5rem;
            border: none;
            width: 90%;
            height: 90%;
            -webkit-box-shadow: var(--shadow);
            -moz-box-shadow: var(--shadow);
            box-shadow: var(--shadow);
        }
    }

    footer{
        font-size: .9rem;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--secondary-light-gra);
        padding: .75rem 0;
        box-shadow: var(--shadow);
        text-align: center;
        color: var(--dark);
    }
}

@media screen and (max-width: 768px) {
    #contact-section{
        height: initial;
        overflow-x: hidden;

        .container{
            flex-direction: column;
            margin: 5rem 0 0 0;


            .text-container{
                max-width: 90%;
                margin-bottom: 3rem;
                margin-right: 0;
            }

            iframe{
                min-height: 35vh;
                width: 100vw;
                // -webkit-box-shadow: 5px 5px 0px 0px #333333aa;
                // -moz-box-shadow: 5px 5px 0px 0px #333333aa;
                // box-shadow: 5px 5px 0px 0px #333333aa;
            }
        }

        footer{
            width: 100%;
            position: static;
            border: none;
        }
    }
}