#five-steps-section{
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .container{
        width: 100%;
        max-width: var(--margined-max-width);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 5rem;

        .text-container{
            width: 50%;
            padding-right: 2rem;

            h1{
                font-size: 3rem;
                color: var(--cark);
            }

            h2 {
                color: var(--cark);
                margin-bottom: 1rem;
            }
            
            h3 {
                color: var(--cark);
                margin-bottom: 1rem;
            }

            p{
                text-indent: 30px;
                margin-bottom: 1rem;
                line-height: 1.5rem;
            }


            ol {
                li {
                    margin-bottom: 1rem;
                    font-size: 0.9rem;
                    text-align: justify;

                    span {
                        font-weight: bold;
                    }

                    b {
                        color: var(--primary-navy);
                    }
                }
            }
        }

        .image-container{
            width: 40%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            position: relative;

            img{
                width: 100%;
            }
        }

    }

    span.graphic-curve{
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 5rem 100vw;
        border-color: transparent transparent var(--dark) transparent;

        &.shadow{
            border-width: 0 0 6rem 100vw;
            border-color: transparent transparent var(--primary-light) transparent;
        }
    }
}

@media screen and (max-width: 767px) {
    #five-steps-section{
        height: initial;
    
        .container{
            flex-direction: column;
            margin: 5rem 0 10rem 0;
            
            .text-container{
                width: 100%;
                padding: 0 2rem;
                margin-bottom: 5rem;

                h1{
                    font-size: 3rem;
                }
    
                h2 {
                    margin-bottom: 1rem;
                }
                
                h3 {
                    font-size: 2rem;
                    margin-bottom: 2rem;
                }
    
                p{
                    font-size: 1.25rem;
                    margin-bottom: 1rem;
                    line-height: 1.75rem;
                }
    
                ol {
                    li {
                        font-size: 1.25rem;
                        line-height: 1.75rem;
    
                        span {
                            font-weight: bold;
                            font-size: 2rem;
                        }
                    }
                }
            }
    
            .image-container{
                width: 100%;
            }
        }
    }
}