@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@700&display=swap');

:root {
  font-size: 18px;
  --margined-max-width: 70vw;

  @media screen and (min-width: 1024px) and (max-width: 1440px){
    font-size: 14px;
    --margined-max-width: 75vw;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px){
    font-size: 12px;
    --margined-max-width: 80vw;
  }

  @media screen and (max-width: 767px) {
    font-size: 10px;
    --margined-max-width: 90vw;
  }

  --primary-gray: #423b3b;
  --primary-red: #ff6b57;
  --secondary-light-gray: #eaeaea;
  --primary-light-gray: #dbdbdb;
  --white: #ffffff;
  --black: #232323;

  --primary: #FFB319;
  --primary-light: #FFE194;
  --secondary:  #B8DFD8;
  --secondary-light: #E8F6EF;
  --dark: #112B3C;
  --light: #EFEFEF;

  --primary-blue: #57acc8;
  --primary-navy: #495a81;
  --secondary-navy: #5077d1;
  --secondary-gray: #2b2b2b;

  --content-width: 98%;
  --content-max-width: 1000px;

  --transition: .2s ease-in;

  --navbar-a-padding: 1.5rem;

  --shadow: #00000005 0px 1px 3px 0px, #1b1f2326 0px 0px 0px 1px;
  --shadow-slider: #32325d40 0px 50px 100px -20px, #0000004d 0px 30px 60px -30px, #0a254059 0px -2px 6px 0px inset;
  --shadow-gentle: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  --font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
}

*{
  font-family: var(--font-sans-serif);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html{
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  scrollbar-width: thin;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: .75rem;
  cursor: pointer;
  // background: var(--white);
}

*::-webkit-scrollbar-thumb {
  // border-radius: .25rem;
  background-color: var(--dark);
}

h1{
  font-family: Heebo, sans-serif;
  font-weight: 700;
}

.animated-button{
  text-decoration: none;
  text-align: center;
  padding: 1rem;
  background: var(--dark);
  color: var(--white);
  border: 1px solid var(--light);
  border-radius: .2rem;
  transition: .2s ease-in;
  cursor: pointer;

  &:hover{
    border: 1px solid var(--dark);
    background: var(--secondary);
    color: var(--dark);
  }
}

.animation-fadeInTop{
          -webkit-animation: fadeInTop 1.2s both;
        -moz-animation: fadeInTop 1.2s both;
        -o-animation: fadeInTop 1.2s both;
        animation: fadeInTop 1.2s both;
}

// Animations
@keyframes fadeInTop {
  0%{
      opacity: 0;
      transform: translateY(-50px);
  }
  100%{
      opacity: 1;
      transform: translateY(0);
  }
}