#offert-section{
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    background: var(--dark);
    color: var(--primary-light-gray);
    overflow-x: hidden;

    .container{
        width: 100%;
        max-width: var(--margined-max-width);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 5rem;

        .text-container{
            width: 65%;
            padding-right: 2rem;

            h1{
                font-size: 3rem;
                color: var(--cark);
            }

            h2 {
                color: var(--cark);
                margin-bottom: 1rem;
            }
            
            h3 {
                color: var(--cark);
                margin-bottom: 1rem;
            }

            p{
                text-indent: 30px;
                margin-bottom: 1rem;
                line-height: 1.5rem;
            }


            ol {
                li {
                    margin-bottom: 1rem;
                    font-size: 0.9rem;
                    text-align: justify;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }

        .image-container{
            width: 30%;
            max-width: 320px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img{
                position: absolute;
                width: 100%;
            }
        }
    }

    span.graphic-curve{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8rem 0 0 100vw;
        border-color: transparent transparent transparent var(--white);

        &.shadow{
            border-width: 10rem 0 0 100vw;
            border-color: transparent transparent transparent var(--primary-light);
        }
    }
}

@media screen and (max-width: 767px) {
    #offert-section{
        height: initial;
    
        .container{
            flex-direction: column;
            margin: 5rem 0 0 0;
            
            .text-container{
                width: 100%;
                padding: 0 2rem;
                margin-bottom: 10rem;

                h1{
                    font-size: 3rem;
                }
    
                h2 {
                    margin-bottom: 1rem;
                }
                
                h3 {
                    font-size: 2rem;
                    margin-bottom: 2rem;
                }
    
                p{
                    font-size: 1.25rem;
                    margin-bottom: 1rem;
                    line-height: 1.75rem;
                }
    
                ol {
                    li {
                        font-size: 1.25rem;
                        line-height: 1.75rem;
    
                        span {
                            font-weight: bold;
                            font-size: 2rem;
                        }
                    }
                }
            }
    
            .image-container{
                display: none;
                width: 100%;
            }
        }

        span.graphic-curve{
            border-width: 4rem 0 0 100vw;
    
            &.shadow{
                border-width: 5rem 0 0 100vw;
            }
        }
    }
}