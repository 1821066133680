#page-not-found{
    width: 100%;
    height: 90vh;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container{
        width: 100%;
        max-width: 20rem;
        display: flex;
        flex-direction: column;
        
 
        h1{
            width: 100%;
            font-size: 4rem;
            margin: 0;
            padding: 0;
            margin-bottom: .5rem;
        }

        h3{
            font-size: 1.5rem;
            font-weight: 400;
            margin: 0;
            padding: 0;
            margin-bottom: 2rem;
        }

        a{
            text-decoration: none;
            background: var(--primary-blue);
            color: var(--white);
            padding: .75rem 1.5rem;
            border-radius: .5rem;
            text-align: center;
        }
    }
}