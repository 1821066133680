#navbar{
    background: var(--primary);
    border-bottom: 1px solid var(--secondary);
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;

    &.navbar-fixed{
        position: fixed;
    }

    &.navbar-static{
        position: static;
    }

    .container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: var(--margined-max-width);
        
        .logo-container{
            display: flex;
            flex-direction: row;

            a{
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;

                img{
                    margin: 1rem 0;
                    height: 2.5rem;
                }
            }
        }

        #menu-toggle {
            display: none;

            &:checked + .menu-button-container .menu-button::before {
                margin-top: 0px;
                transform: rotate(45deg);
            }
                  
            &:checked + .menu-button-container .menu-button {
                background: #ffffff00;
            }
                  
            &:checked + .menu-button-container .menu-button::after {
                margin-top: 0px;
                transform: rotate(-45deg);
            }
        }

        .menu-button-container {
            display: none;
            height: 100%;
            width: 30px;
            cursor: pointer;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .menu-button,
            .menu-button::before,
            .menu-button::after {
                display: block;
                background-color: var(--light);
                position: absolute;
                height: 4px;
                width: 30px;
                transition: transform .4s cubic-bezier(0.23, 1, 0.32, 1);
                border-radius: 2px;
            }
                
            .menu-button::before {
                content: '';
                margin-top: -8px;
            }
                
            .menu-button::after {
                content: '';
                margin-top: 8px;
            }
        }

        .links-container{
            display: flex;
            flex-direction: row;
            align-items: center;

            a {
                padding: .75rem 1.5rem;
                border-radius: .25rem;
                font-size: 1.25rem;
                margin-left: .25rem;
                color: var(--light);
                text-transform: none;
                text-decoration: none;
                white-space: nowrap;
                transition: .15s ease-in;
                cursor: pointer;
        
                &:hover{
                    background: var(--dark);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #navbar {

        a {
            width: 100%;
            text-align: center;

            &:hover{
                background: var(--secondary);
            }
        }

        .container{

            .logo-container{
                
                a{
                    img{
                        height: 3rem;
                    }
                }
            }
            #menu-toggle:checked + .links-container{
                display: flex;
            }

            .menu-button-container {
                display: flex;
            }   

            .links-container{
                background: var(--dark);
                position: absolute;
                top: 60px;
                left: 0;
                width: 100%; 
                flex-direction: column;
                align-items: center;
            }

            #menu-toggle ~ .links-container a {
                height: 0;
                padding: 0;
                overflow: hidden;
                transition: height .4s cubic-bezier(0.23, 1, 0.32, 1);
            }

            #menu-toggle:checked ~ .links-container a {
                height: initial;
                padding: var(--navbar-a-padding);
                transition: padding .4s cubic-bezier(0.23, 1, 0.32, 1);
            }

            #menu-toggle:checked ~ .links-container {
                border-bottom: 1px solid var(--light);
            }
        }
    }
}
