#welcome-section{
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    overflow-x: hidden;

    .img-container{
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        img{
            min-height: 100%;
            min-width: 100%;
            filter: brightness(30%);
            -webkit-filter: brightness(30%);
            -moz-filter: brightness(30%);
        }
    }

    .welcome-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: var(--margined-max-width);
        z-index: 5;
        color: var(--light);

        .container{
            max-width: 700px;

            h1{
                font-size: 3.5rem;
                margin: 0 0 2rem;

                strong{
                    color: var(--primary);
                }
            }
    
            p {
                color: var(--light);
                font-size: 1.25rem;
                margin: 0 0 2rem;
            }
    
            a{
                width: 10rem;
                padding: 1rem;
                background: var(--dark);
                border: 1px solid #eeeeee;
                border-radius: .2rem;
                transition: .2s ease-in;
                cursor: pointer;
    
                &:hover{
                    background: var(--secondary);
                    color: var(--dark);
                }
            }
        }
    }

    span.graphic-curve{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width:  0 0 12rem 100vw;
        border-color: transparent transparent var(--white) transparent;

        &.shadow{
            border-width: 0 0 13rem 100vw;
            border-color: transparent transparent  var(--primary-light) transparent;
        }
    }
}