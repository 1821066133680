#about-me-section{
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    overflow-x: hidden;

    .container{
        width: 100%;
        max-width: var(--margined-max-width);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        // margin-bottom: 5rem;

        .text-container{
            width: 48%;
            max-width: 500px;
            padding-right: 2rem;

            h1{
                font-size: 3rem;
                color: var(--cark);
            }

            p{
                font-size: 1.05rem;
                color: var(--primary-gray);
                text-indent: 30px;
                margin-bottom: 1rem;
                line-height: 1.5rem;
            }

            .social-links {
                font-size: 2.25rem;
                padding: 0 4.5rem;
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                a {
                    color: #555555;
                    cursor: pointer;
                    transition: 0.2s;
                }

                a.google-link:hover {
                    color: #D73D32;
                }

                a.facebook-link:hover {
                    color: #0866FF;
                }

                a.tiktok-link:hover {
                    color: #000000;
                }

            }
        }

        .image-container{
            width: 35%;
            max-width: 320px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            img{
                position: absolute;
                width: 100%;
                
                &.first{
                    animation: rotate-first 10s infinite;
                }

                &.second{
                    animation: rotate-second 10s infinite;
                }

                &.third{
                    animation: rotate-third 10s infinite;
                }

                @keyframes rotate-first {
                    0%{
                        transform: translateX(6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    12%{
                        box-shadow: var(--shadow-slider);
                        transform: translateX(0);
                        width: 100%;
                        z-index: 2;
                    }
                    33%{
                        box-shadow: var(--shadow-slider);
                        transform: translateX(0);
                        width: 100%;
                        z-index: 2;
                    }
                    45%{
                        transform: translateX(-6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    66%{
                        transform: translateX(-6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    78%{
                        transform: translateX(6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    100%{
                        transform: translateX(6rem);
                        width: 70%;
                        z-index: 1;
                    }
                }
                @keyframes rotate-second {
                    0%{
                        transform: translateX(-6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    12%{
                        transform: translateX(6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    33%{
                        transform: translateX(6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    45%{
                        box-shadow: var(--shadow-slider);
                        transform: translateX(0);
                        width: 100%;
                        z-index: 2;
                    }
                    66%{
                        box-shadow: var(--shadow-slider);
                        transform: translateX(0);
                        width: 100%;
                        z-index: 2;
                    }
                    78%{
                        transform: translateX(-6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    100%{
                        transform: translateX(-6rem);
                        width: 70%;
                        z-index: 1;
                    }
                }
                @keyframes rotate-third {
                    0%{
                        box-shadow: var(--shadow-slider);
                        transform: translateX(0);
                        width: 100%;
                        z-index: 2;
                    }
                    12%{
                        transform: translateX(-6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    33%{
                        transform: translateX(-6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    45%{
                        transform: translateX(6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    66%{
                        transform: translateX(6rem);
                        width: 70%;
                        z-index: 1;
                    }
                    78%{
                        box-shadow: var(--shadow-slider);
                        transform: translateX(0);
                        width: 100%;
                        z-index: 2;
                    }
                    100%{
                        box-shadow: var(--shadow-slider);
                        transform: translateX(0);
                        width: 100%;
                        z-index: 2;
                    }
                }
            }
        }
    }

    span.graphic-curve{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 10rem 100vw;
        border-color: transparent transparent var(--dark) transparent;
        z-index: -1;

        &.shadow{
            border-width: 0 0 11rem 100vw;
            border-color: transparent transparent var(--primary-light) transparent;
        }
    }
}

@media screen and (max-width: 768px) {
    #about-me-section{
        height: initial;
    
        .container{
            flex-direction: column;
            margin: 5rem 0 0 0;
    
            .text-container{
                width: 100%;
                padding-right: 0;
                margin-bottom: 3rem;
            }
    
            .image-container{
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 0;
    
                img{
                    width: 34vw;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    
                    position: static;
                    // width: 100%;
                    
                    &.first{
                        animation: none;
                    }
    
                    &.second{
                        animation: none;
                    }
    
                    &.third{
                        animation: none;
                    }
                }
            }
        }

        span.graphic-curve{
            border-width: 0 0 2rem 100vw;
    
            &.shadow{
                border-width: 0 0 2.5rem 100vw;
                border-color: transparent transparent var(--primary-light) transparent;
            }
        }
    } 
}